<template>
    <div class="hello">
        <!-- 导航栏 -->
        <div class="menu">
            <!-- logo -->
            <a href="#">
                    <img class="Logo"  src="./Footer_img/logo.png" alt="">
                </a>
            <div class="box">

            <!-- 导航栏列表 -->
                <ul class="minMenu">
                    <li class="title1" @click="openPage0()">大赛首页</li>
                    <li class="title2" @click="openPage1()">
                        赛事介绍
                        <span id="topRealName"></span>
                            <b class="caret"></b>
                            <el-collapse-transition>
                                <div class="changeBox" v-show="pull_show ">
                                <p class="changePassword" @click="brief">大赛简介</p>
                                <p class="changeDisable"  @click="Regulations">大赛章程</p>
                                <p class="changePassword" @click="framework">组织架构</p>
                                <p class="changePassword" @click="retrospect">往届回顾</p>
                                <p class="changeDisable"  @click="journalism">新闻动态</p>
                                <p class="changeDisable"  @click="platform">平台介绍</p>
                                <p class="changeDisable"  @click="openPage3()">赛区规划</p>
                            </div>
                            </el-collapse-transition>
                    </li>
                    <li class="title3" @click="openPage2()">官方文件</li>
                    <li class="title4" @click="certificateInquiry">证书查询</li>
                    <li class="title5"  @click="openPage4()">
                        大赛培训
                        <span id="topRealName"></span>
                            <b class="caret"></b>
                            <el-collapse-transition>
                                <div class="changeClick" v-show="competition_show ">
                                <p class="changePassword" @click="training">培训平台</p>
                                <p class="changeDisable"  @click="simulation">仿真回放</p>
                                <!-- <p class="changePassword" @click="Live_broadcast">直播回放</p> -->
                            </div>
                            </el-collapse-transition>
                    </li>
                    <li class="title6" @click="openPage5()">联系我们</li>
                    <!-- <li class="title6" @click="openPage6()">移动端</li> -->
                    <div><el-button @click="inpbut" type="primary" id="inp" >报名参赛</el-button></div>
                </ul>
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
   import $ from "jquery";
  export default {
    name: 'Home',
    components: {
    },
    data(){
      return {
        pull_show:false,
        competition_show:false,
      }
    },
    methods:{
        openPage0(){
            this.$router.push('/HomePage')
        },
        openPage1(){
           this.pull_show = !this.pull_show
           console.log(this.pull_show)
            // this.$router.push('/introduce')
        },
        openPage2(){
            this.$router.push('/File')

        },
        openPage3(){
            this.$router.push('/fingerpost')

        },
        openPage4(){
           this.competition_show = !this.competition_show
            // this.$router.push('/Train')
        },
        openPage5(){
            this.$router.push('/Contact')
        },
        openPage6(){
            this.$router.push('/ExamArrangement')
        },
        certificateInquiry(){
            this.$router.push('/certificate')
        },
        brief(){
            console.log("大赛简介")
            this.$router.push('/Brief')
        },
        framework(){
            console.log("组织架构")
            this.$router.push('/Framework')
        },
        retrospect(){
            console.log("往届回顾")
            this.$router.push('/Retrospect')
        },
        journalism(){
            console.log("新闻动态")
            this.$router.push('/Journalism')
        },
        platform(){
            console.log("平台介绍")
            this.$router.push('/Platform')
        },
        Regulations(){
            // this.$router.push('/Regulations');
            window.open('./static/passAge/大唐杯”全国大学生新一代信息通信技术大赛章程.pdf');
        },
        inpbut(evt){
            this.$router.push('/Register')
            // window.open('https://www.ncie.org.cn/Category_1397/Index.aspx', '_blank')
            let target = evt.target;
        if(target.nodeName == "SPAN"){
            target = evt.target.parentNode;
        }
        target.blur();
        },
        training(){
            this.$router.push('/Train')
        },
        simulation(){
            this.$router.push('/simulation')
        },
        Live_broadcast(){
            this.$router.push('/Livebroadcast')
        },
    },
    mounted(){
        if(this.$route.name == "HomePage"){
            $(".title1").addClass("divClass ")
        }else if (this.$route.name == "introduce"){
            $(".title2").addClass("divClass ")
        }else if (this.$route.name == "File"){
            $(".title3").addClass("divClass ")
        }else if (this.$route.name == "fingerpost"){
            $(".title4").addClass("divClass ")
        }else if (this.$route.name == "Train"){
            $(".title5").addClass("divClass ")
        }else if (this.$route.name == "Contact"){
            $(".title6").addClass("divClass ")
        }
    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">
  .divClass{
  color: #FFFFFF !important;
}
  @import './Footer.scss';
  </style>
  