<template>
    <!-- 首页 -->
    <div id="wrap">
        <!-- 底部 -->
        <div class="Footer">
            <div class="Footerbox">
              <img class="LOGO_in"  src="./Bottom_img/Bottom_column.png" alt="">
              <p class="Footer_text">版权所有: 大唐移动通信设备有限公司</p>
              <p class="Footer_text_two">备案号: 京ICP备2024085663号</p>

                <!-- <div class="Footerleft"> -->
                    <!-- logo -->
                    <!-- <a href="/DTBOfficialWebsite">
                        <img class="Logo2"  src="./Bottom_img/logo.png" alt="">
                    </a>
                    <div class="Login">
                      <img style="width: 80%;" src="./Bottom_img/Login.jpg" alt="">
                    </div>
                    <span>微信扫一扫关注我们</span> -->

                    <!-- 介绍 -->
                    <!-- <p class="Introduce">
                      大赛始终秉持开放共赢的理念，帮助参赛师生获得个人发展与能力提升，为高校提供行业顶尖的竞赛设备、平台与合作模式。
                    </p> -->
                    <!-- 联系我们 -->
                    <!-- <div class="contactUs">
                        <span class="cont0">Email 地址:</span>
                        <span class="cont1">联系我们:</span>
                        <span class="cont2">Yuanxing@cictmobile.com</span>
                        <span class="cont3">186 0125 6072</span>
                    </div> -->
                <!-- </div> -->

                <!-- <div class="Footerright">
                      <img class="Footerimg" src="./Bottom_img/LogoG.png" alt="">
                </div> -->

            </div>

        </div>
        <div class="Bottom">
            <div class="Bottom_box">
                <span class="BottomMenu">
                    Copyright 2022, 大唐移动
                </span>

                <div class="home">
                    <el-backtop></el-backtop>
                    <div class='content'></div>
                </div>

                <ul class="BotminMenu">
                    <li @click="backTop">回到顶部</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        showBtn: false, // 回到顶部，默认是false，就是隐藏起来
      }
    },

    created() {

    },
    mounted() {
      window.addEventListener("scroll", this.showbtn, true);
    },
    methods: {
      // 回到顶部
      showbtn() {
        let that = this;
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        that.scrollTop = scrollTop;
        if (scrollTop > 900) {
            this.showBtn = true;
        } else {
            this.showBtn = false;
        }
      },
      
		/*回到顶部实现过程注解：
        1.获取当前页面距离顶部的距离
        2.计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是一个减法
        3.用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
        4.最后在移动到顶部的时候，清除定时器
        */
        
      backTop() {
        var timer = setInterval(function () {
          let osTop =
              document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5);
          document.documentElement.scrollTop = document.body.scrollTop =
              osTop + ispeed;
          this.isTop = true;
          if (osTop === 0) {
              clearInterval(timer);
          }
        }, 10);
      },
    }
  };
</script>

<style scoped lang="scss">
@import './Bottom.scss';
</style>